import ReactGA from "react-ga4";
import { fetchAuthSession, fetchMFAPreference } from "aws-amplify/auth";
import { getTenants, getTenant } from "../../api/ApiClient";
import { isRoot } from "../../util/userControl";
import { captureSentryError } from "../../util/sentry";

const defaultRole = "Read_Only";

const getTenantList = async (userData) => {
  try {
    let tenantItems = await getTenants();
    return formatTenants(tenantItems);
  } catch (aggregateError) {
    captureSentryError(aggregateError, userData, "getTenants in Login.js");
  }
};

const formatTenants = (tenantItems) => {
  if (tenantItems && tenantItems.length > 0) {
    tenantItems = tenantItems.map((item) => ({
      label: item.name,
      value: item.id,
      id: item.id,
      name: item.id,
      role: item.role,
      is_onboarding: item.is_onboarding,
      parent: item.parent
    }));
    tenantItems = tenantItems.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
    tenantItems = tenantItems.sort((a, b) =>
      a.is_onboarding === b.is_onboarding ? 0 : !a.is_onboarding ? -1 : 1
    );
    return tenantItems;
  }
};

const getTenantConfiguration = async (tenant, userData) => {
  try {
    return await getTenant(tenant);
  } catch (aggregateError) {
    captureSentryError(aggregateError, userData, 'getTenant in getTenantConfiguration')
  }
};

const redirectParentTenant = () => {
  const parentRoutes = ["/", "/onboarding", "/onboarding/people", "/onboarding/tenants"];
  if (!parentRoutes.includes(window.location.pathname)) {
    window.location.href = "/";
  }
}

export const setUserSession = async ({setSetupMFA, setUserData,}) => {
  try {
    const mfaInfo = await fetchMFAPreference();
    if (mfaInfo.preferred === "TOTP" && mfaInfo.enabled) {
      const session = await fetchAuthSession();
      setSetupMFA(false);
      const idToken = session?.tokens?.idToken;
      const { sub: userId, email } = idToken.payload;
      const userTenants = await getTenantList({ userId, email });
      let selectedTenant =
        localStorage.getItem("tenant") || userTenants[0].value;
      let selectedTenantRole = defaultRole;
      let foundTenant = userTenants.filter(
        (item) => item.value === selectedTenant
      );
      // If the tenant in localStorage isn't in the user's tenants use the first tenant in the list
      if (foundTenant.length === 0) {
        selectedTenant = userTenants[0].value;
        selectedTenantRole = userTenants[0].role;
      } else {
        selectedTenantRole = foundTenant[0].role;
      }
      const isRootUser = isRoot(selectedTenantRole);
      let userType = isRootUser ? "root" : "tenant";
      let tenant = null;
      let gaTenant = null;
      if (selectedTenant) {
        tenant = selectedTenant;
        gaTenant = selectedTenant;
      }
      const updateUserData = {
        tenant,
        userType,
        userId,
        email,
        role: selectedTenantRole,
        userTenants: userTenants,
      };
      const tenantConfig = tenant ? await getTenantConfiguration(tenant, { userId, email }) : {};
      if(tenantConfig.is_parent) redirectParentTenant();
      setUserData((state) => ({
        ...state,
        ...updateUserData,
        tenantConfig : tenantConfig
      }));
      localStorage.setItem("tenant", tenant);
      ReactGA.gtag("set", "user_properties", { Tenant: gaTenant });
    } else {
      setSetupMFA(true);
    }
  } catch (e) {
    setUserData((state) => ({
      ...state,
      tenant: "",
      userType: "",
      userId: "",
      email: "",
      role: "",
      userTenants: [],
    }));
    captureSentryError(e, { userId : "unknown", email : "unknown"}, "setUserSession in Login.js");
  }
};