import React, { useContext, useEffect, useState } from "react";
import {
  getCurrentUser,
  signIn,
  signOut,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";
import {
  Authenticator,
  useAuthenticator,
  translations,
} from "@aws-amplify/ui-react";
import { formFields, headers, errors, i18n } from "./loginConfig";
import "../../styles/pages/Login/Login.scss";
import Router from "../../navigation/Router";
import UserData from "../../store/User/UserData";
import { Cache } from "aws-amplify/utils";
import ErrorIcon from "@mui/icons-material/Error";
import { Loader } from "../../components/Loader/Loader";
import App2FA from "./App2FA";
import { setUserSession } from "./util";
import AZWGLogo from '../../assets/logos/az-wg-logo.svg';

I18n.putVocabularies(translations);
I18n.setLanguage("en-US");
I18n.putVocabulariesForLanguage("en", i18n);

const Login = () => {
  const [, setUserData] = useContext(UserData);
  const { route } = useAuthenticator((context) => [context.route]);
  const { toForgotPassword, toSignIn } = useAuthenticator();
  const [error, setError] = useState();
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [setupMFA, setSetupMFA] = useState("pending");

  useEffect(() => {
    if (!localStorage.getItem("hasRefreshed")) {
      localStorage.setItem("hasRefreshed", true);
      setTimeout(() => window.location.reload(), 100); // delay refresh for 1Password to maintain input focus
    }
    return () => localStorage.removeItem("hasRefreshed");
  }, []);

  useEffect(() => {
    const setup = async () => {
      try {
        setLoadingUserData(true);
        setError();
        await setUserSession({ setSetupMFA, setUserData });
      } finally {
        setLoadingUserData(false);
      }
    };
    (route === "authenticated" && !loadingUserData) && setup();
    // eslint-disable-next-line
  }, [route, setupMFA]);

  useEffect(() => setError(), [route]);

  const services = {
    async handleSignIn(formData) {
      Cache.clear(); // clear any previous user session
      setSetupMFA(true); // ensure MFA setup is required

      try {
        const response = await signIn(formData);
        if (
          response.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        ) {
          setSetupMFA(false);
        }
        return response;
      } catch (e) {
        formFields.setupTOTP.QR.totpUsername = "";
        e.name === "UserNotFoundException" ||
        e.name === "NotAuthorizedException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleConfirmSignIn(formData) {
      Cache.clear(); // clear any previous user session
      try {
        return await confirmSignIn(formData);
      } catch (e) {
        e.name === "CodeMismatchException"
          ? setError(errors.invalidCode)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleForgotPassword({ username, clientMetadata }) {
      try {
        return await resetPassword({
          username,
          options: {
            clientMetadata,
          },
        });
      } catch (e) {
        e.name === "UserNotFoundException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleForgotPasswordSubmit(formData) {
      const { username, newPassword, confirmationCode, clientMetadata } =
        formData;
      try {
        return await confirmResetPassword({
          username,
          newPassword,
          confirmationCode,
          options: {
            clientMetadata,
          },
        });
      } catch (e) {
        e.name === "InvalidParameterException"
          ? setError(errors.invalidPasswordFormat)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },
  };

  return loadingUserData || route === "idle" || !route ? (
    <div className="root-loader">
      <Loader />
    </div>
  ) : route === "authenticated" && !setupMFA ? (
    <>
      <Router />
      <Authenticator />
    </>
  ) : (
    <div className={`login-container ${route === "setup" ? "hidden" : ""}`}>
      <div
        className="bg-actzero"
        style={{ backgroundImage: `url('/mountain-scene.jpg')` }}
      >
        <div className={"breach-info"}>
          <p className={"breach-number"}>
            <span>BREACH?</span> 1-855-917-4981
          </p>
          <p
            className={"pointer breach-email"}
            onClick={() =>
              (window.location = "mailto:threathunting@actzero.ai")
            }
          >
            threathunting@actzero.ai
          </p>
        </div>
        <div className="mask-bg" />
      </div>
      <div className={"authenticator-container"}>
        <div className="logo">
          <img src={AZWGLogo} alt="Actzero-WatchGuard"/>
        </div>
        {route === "authenticated" && setupMFA && setupMFA !== "pending" ? (
          <div>
            <App2FA setSetupMFA={setSetupMFA} />
          </div>
        ) : (
          <>
            <>
              <div className={"header " + route}>{headers[route]}</div>
              <div className="subheader">
                {route === "signIn" &&
                  "Please enter your credentials to proceed:"}
              </div>
            </>
            {error && (
              <div className="error-msg">
                <ErrorIcon className="err-icon" /> {error}
              </div>
            )}
            <Authenticator
              formFields={formFields}
              hideSignUp={true}
              submitButtonText="Login"
              className={route}
              services={services}
            />
            {route === "signIn" ? (
              <div className="reset-container">
                {"Forgot your password? "}
                <span className="link" onClick={toForgotPassword}>
                  Reset password
                </span>
              </div>
            ) : (
              <div
                className="return-btn"
                onClick={() => {
                  if(route === "authenticated") signOut();
                  toSignIn();
                }}
              >
                Back to Sign In
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
