import React, {useEffect, useState} from 'react';
import * as d3 from 'd3';

const rootStyles = getComputedStyle(document.documentElement);
let grey = rootStyles.getPropertyValue('--grey').trim()
let yellow = rootStyles.getPropertyValue('--yellow').trim()

let positiveColors = ['rgb(74, 171, 83, 0.9)', 'rgb(51, 51, 51, 0.01)'];
let negativeColors = ['rgb(51, 51, 51, 0.01)', 'rgb(240, 192, 55, 0.9)'];

const BarChart = ({data, width, height, useLowercase = false, fontColor}) => {
  const [graphSVG, setGraphSVG] = useState();

  useEffect(() => {
    let svgRef = graphSVG;
    if (!data || data.length === 0 || !svgRef) return;

    const margin = {top: 30, right: 30, bottom: 40, left: 50};
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3.select(svgRef)
      .attr('width', width)
      .attr('height', height);

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const formatDate = d3.timeFormat(`%b-%y`);

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => formatDate(d.date)))
      .range([0, innerWidth])
      .padding(0.2);

    let yMin = d3.min(data, d => d.value)
    let yMax = d3.max(data, d => d.value)

    if (yMax < 0) {
      yMax += 20;
    }
    if (yMin > 0) {
      yMin -= 20;
    }
    if (yMin === yMax) {
      yMax = yMin + 100;
      yMin = yMin - 100
    } // still has a y axis when values are all the same

    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([innerHeight, 0])
      .nice();

    const xAxis = d3.axisBottom(xScale).tickFormat(d => d.split("-")[0]);
    const yAxis = d3.axisLeft(yScale).ticks(6).tickFormat(d3.format("~s"));

    const domain = g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${yScale(yMin)})`)

    domain.call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'middle')
      .style('color', `${fontColor || grey}`)
      .style('text-transform', `${useLowercase ? 'capitalize' : 'uppercase'}`)
      .style('font-size', '10px');

    domain.selectAll("path,line").remove();

    const range = g.append('g')
      .attr('class', 'y-axis')

    range.call(yAxis)
      .style('color', `${fontColor || grey}`)
      .style('text-transform', 'uppercase')
      .style('font-size', '10px')
      .style("font-family", "Open Sans");


    range.selectAll("path,line").remove();

    svg.append('defs')
      .append('linearGradient')
      .attr('id', 'positiveGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%')
      .selectAll('stop')
      .data(positiveColors)
      .enter()
      .append('stop')
      .style('stop-color', (d) => d)
      .attr('offset', (d, i) => {
        return 100 * (i / (positiveColors.length - 1)) + '%';
      })

    svg.append('defs')
      .append('linearGradient')
      .attr('id', 'negativeGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%')
      .selectAll('stop')
      .data(negativeColors)
      .enter()
      .append('stop')
      .style('stop-color', (d) => d)
      .attr('offset', (d, i) => {
        return 100 * (i / (negativeColors.length - 1)) + '%';
      })

    let values = g.selectAll('.bar')
      .data(data);


    values.join('rect')
      .attr('class', 'bar')
      .attr('x', d => (xScale(formatDate(d.date)) + xScale.bandwidth() / 2) - 22)
      .attr('y', d => (d.value >= 0 ? yScale(d.value) : yScale(0)))
      .attr('width', "45px")
      .attr('height', d => Math.abs(yScale(0) - yScale(d.value)))
      .attr('fill', d => (d.value >= 0 ? 'url(#positiveGradient)' : 'url(#negativeGradient)'))

    values.enter().append("text")
      .attr("class", "bar")
      .attr("text-anchor", "middle")
      .attr('x', d => (xScale(formatDate(d.date)) + xScale.bandwidth() / 2))
      .attr('y', d => (d?.value < 0 || d?.value === null) ? yScale(5) : yScale(d?.value + 5))
      .style("fill", (d) => (d.value < 0 ? yellow : fontColor))
      .style("font-size", "20px")
      .text(d => d.value === null ? "N/A" : `${Math.round(d.value)}%`); // display N/A if data point is null

    return () => d3.select(svgRef).selectAll("*").remove();
  }, [data, height, width, graphSVG, fontColor, useLowercase]);

  return !data.length ? (
    <div className="no-data">No Data</div>
  ) : (
    <svg ref={(ref) => setGraphSVG(ref)} width={width} height={height}/>
  );
};

export default BarChart;
