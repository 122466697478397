import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserData from "../../store/User/UserData";
import "../../styles/pages/AdhocInvestigation/AdhocInvestigation.scss";
import { modules, startPoints } from "./config";
import TenantDropDownStyles from "../../styles/pages/Onboarding/TenantDropDownStyles";
import Select from "react-select";
import LoaderButton from "../../components/Button/LoaderButton";
import { captureSentryError } from "../../util/sentry";
import ResultsPanel from "./components/ResultsPanel";
import DetectionPanels from "./components/DetectionPanels";
import SummaryPanel from "./components/SummaryPanel";
import CommentModal from "./components/CommentModal";
import ErrorIcon from "@mui/icons-material/Error";
import {
  initiateAIEResponse,
  pollAIEResponse,
  getAIEDetectionInvestigation,
} from "../../api/ApiClient";
import {
  validateInput,
  handleDetectionSearch,
  fetchInvestigationData,
  handlePollResults,
} from "./util";

const AdhocInvestigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData] = useContext(UserData);
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedStartPoint, setSelectedStartPoint] = useState();
  const [selectedModules, setSelectedModules] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resultsLoading, setResultsLoading] = useState(false);
  const [results, setResults] = useState({ modules: [] });
  const [error, setError] = useState();
  const [commentModalData, setCommentModalData] = useState(false);
  const [hasUrlParams, setHasUrlParams] = useState(false);
  const [traceID, setTraceID] = useState();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const tenant = params.get("tenant");

    if (tenant) {
      setSelectedTenant(userData.userTenants.find((t) => t.value === tenant));
    }
    if (id) {
      setSearchValue(id);
    }
    if (tenant && id) {
      setHasUrlParams(true);
      handleDetectionSearch({
        tenant,
        id,
        setResultsLoading,
        setResults,
        getAIEDetectionInvestigation,
        setTraceID,
        captureSentryError,
        userData,
        setError,
      });
    } else {
      setHasUrlParams(false);
    }
    // eslint-disable-next-line
  }, [location.search, userData.userTenants]);

  useEffect(() => {
    if (selectedStartPoint)
      setSelectedModules(
        modules.filter((module) =>
          selectedStartPoint.values.includes(module.value)
        )
      );
  }, [selectedStartPoint]);

  const handleSearch = async () => {
    if (
      !validateInput({ selectedTenant, selectedModules, searchValue, setError })
    )
      return;
    try {
      if (selectedModules) {
        setResultsLoading(true);
        const expectedResults = {
          llm_summary: {},
          modules: selectedModules.map((module) => ({
            module: module.label,
          })),
        };
        if (selectedStartPoint.agent === "o365_user")
          expectedResults.timeline = "";
        setResults(expectedResults);
        const sessionId = await fetchInvestigationData({
          selectedTenant,
          selectedStartPoint,
          searchValue,
          selectedModules,
          initiateAIEResponse,
          pollResults,
          setError,
          captureSentryError,
          userData,
        });
        pollResults(sessionId);
      }
    } catch (e) {
      setResultsLoading(false);
      captureSentryError(e, userData, "GET adhoc investigation results");
      setError(e.message);
    }
  };

  const pollResults = async (session_id, tryCount = 0) => {
    try {
      if (tryCount >= 30) {
        setError("Polling limit reached. Please try again later.");
        setResultsLoading(false);
        return;
      }

      const response = await pollAIEResponse(selectedTenant.value, session_id, {
        agent_type: selectedStartPoint.agent,
      });

      if (response.files?.length === selectedStartPoint.expectedFiles) {
        handlePollResults({
          response,
          setTraceID,
          setResults,
          setError,
          setResultsLoading,
          captureSentryError,
          userData,
        });
      } else {
        setTimeout(() => {
          pollResults(session_id, tryCount + 1);
        }, 5000);
      }
    } catch (e) {
      setError(e.message);
      captureSentryError(
        e,
        userData,
        "pollResults API in AdhocInvestigation.js"
      );
    }
  };

  const handleReset = () => {
    navigate(location.pathname);
    setSelectedTenant(null);
    setSelectedStartPoint(null);
    setSelectedModules([]);
    setSearchValue("");
    setResults({ modules: [] });
    setError(null);
    setHasUrlParams(false);
  };

  const selectConfigs = [
    {
      label: "Tenant",
      className: "tenant",
      options: userData.userTenants,
      value: selectedTenant,
      onChange: setSelectedTenant,
    },
    {
      label: "Start Point",
      className: "start-point",
      options: startPoints,
      value: selectedStartPoint,
      onChange: setSelectedStartPoint,
      isDisabled: hasUrlParams || !selectedTenant,
    },
    {
      label: "Investigation Tools",
      className: "investigation-tools",
      options: modules.filter((mod) =>
        selectedStartPoint?.values.includes(mod.value)
      ),
      value: selectedModules,
      onChange: (val) => {
        setSelectedModules(val);
      },
      isMulti: true,
      isDisabled: hasUrlParams || !selectedStartPoint,
    },
  ];

  return (
    <div className={"adhoc-container"}>
      {commentModalData && (
        <CommentModal
          data={commentModalData}
          handleClose={() => setCommentModalData()}
          traceID={traceID}
        />
      )}
      <div className={"header-row"}>
        <p className={"page-title"}>ADHOC INVESTIGATION</p>
      </div>

      <div className="adhoc-content">
        <div className="search-container">
          {selectConfigs.map((config, index) => (
            <div
              className={`input-container ${config.className} ${
                config.isDisabled ? "disabled" : ""
              }`}
              key={index}
            >
              <div className="label">{config.label}</div>
              <Select
                className={`selection ${config.className}`}
                styles={TenantDropDownStyles}
                options={config.options}
                value={config.value}
                onChange={config.onChange}
                isMulti={config.isMulti || false}
                isDisabled={config.isDisabled || false}
              />
            </div>
          ))}
          <div className="input-container search-box">
            <input
              className={`input-box`}
              value={searchValue}
              placeholder={selectedStartPoint?.inputLabel}
              onChange={(e) => setSearchValue(e.target.value)}
              disabled={!hasUrlParams && !selectedModules.length}
            />
          </div>
          <div className="input-container submit-button-container">
            <LoaderButton
              className={"submit-button"}
              text={hasUrlParams ? "RESET" : "SUBMIT"}
              onClick={() => (hasUrlParams ? handleReset() : handleSearch())}
            />
          </div>
          {error && (
            <div className="error-message">
              <ErrorIcon className="err-icon" /> {error}
            </div>
          )}
        </div>

        <div className="results-container">
          {!hasUrlParams ? (
            <>
              <div className="module-results">
                {results?.modules?.map((result) => (
                  <ResultsPanel
                    data={result}
                    key={result.module}
                    setCommentModalData={setCommentModalData}
                    traceID={traceID}
                  />
                ))}
              </div>
              <div className={`summary-results`}>
                {results.hasOwnProperty("llm_summary") && (
                  <SummaryPanel
                    loading={resultsLoading}
                    data={results?.llm_summary?.summary}
                    heading={"Summary"}
                  />
                )}
              </div>
              {results.hasOwnProperty("timeline") && (
                <div className={`timeline-results`}>
                  <SummaryPanel
                    data={results.timeline}
                    className="adhoc-timeline"
                    heading="Timeline"
                    loading={resultsLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className={`summary-results detection`}>
              {results.hasOwnProperty("llm_summary") && (
                <DetectionPanels
                  loading={resultsLoading}
                  data={results.llm_summary}
                  className={"detection"}
                  setCommentModalData={setCommentModalData}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdhocInvestigation;
